<template>
  <div class="login">
    <van-cell title="注册" to="/account/register" />
    <img src="http://fresh.huruqing.cn/img/logo.adec77d3.png" alt="" />
    <van-cell-group>
      <van-field v-model="phone" placeholder="请输入手机号">
        <template #button>
          <input
            type="button"
            :value="text"
            class="f12 bg-fff"
            style="border: none"
            @click="getCode()"
          />
        </template>
      </van-field>
      <van-field v-model="setCode" placeholder="请输入验证码" />
    </van-cell-group>
    <van-button
      @click="login"
      type="default"
      style="
        width: 82%;
        margin: 30px auto;
        display: block;
        color: #fff;
        background-color: #b13d38;
      "
      >短信登录</van-button
    >
  </div>
</template>
<script></script>

<style lang="less" scoped>
.login {
  .van-cell {
    text-align: right;
    padding-right: 28px;
    border-bottom: 1px solid #f5f6f7;
    font-size: 14px;
  }
  img {
    width: 40%;
    display: block;
    margin: 78px auto;
  }
  .van-cell-group {
    width: 88%;
    margin: 0 auto;
  }
  .van-field {
    ::-webkit-input-placeholder {
      color: #949494;
    }
  }
}
</style>
<script>
import $http from "@/utils/http";
export default {
  data() {
    return {
      phone: "13533335858",
      setCode: "",
      text: "获取验证码",
      count: 5, //倒计时键
      //是否正在倒计时
      counting: false,
    };
  },
  methods: {
    //验证数据是否合法
    check(flag = "") {
      let phoneReg = /^1[3456789]\d{9}/;
      if (!flag) {
        if (!this.phone) {
          this.$toast("手机号码不能为空");
          return false;
        }
        if (!phoneReg.test(this.phone)) {
          this.$toast("手机号码不正确");
          return false;
        }
        return true;
      }
      if (!this.setCode) {
        this.$toast("验证码不能为空");
        return false;
      }
      return true;
    },
    //倒计时
    countDown() {
      this.counting = true;
      let timer = setInterval(() => {
        this.text = this.count--;

        if (this.text === 0) {
          this.text = "重新获取验证码";
          //重置数字
          this.count = 5;
          clearInterval(timer);
          //倒计时结束
          this.counting = false;
        }
      }, 1000);
    },
    getCode() {
      //是否正在倒计时
      if (this.counting) {
        return false;
      }

      //验证手机号是否符合规则
      if (!this.check()) {
        return false;
      }
      //倒计时
      this.countDown();

      //发送验证码
      $http
        .post("/user/getSmsCode", { phone: this.phone, setCode: this.setCode })
        .then((res) => {
          this.setCode = res.smsCode;
          //console.log(res);
        });
    },
    login() {
      //验证表单数据
      if (!this.check(true)) {
        return false;
      }

      //请求登录接口
      let data = { phone: this.phone, smsCode: this.setCode };
      $http.post("/user/login", data).then((res) => {
        //隐藏手机号码中间四位
        let reg = /^(\d{3})\d{4}(\d{4})$/;
        let tel = this.phone.replace(reg, "$1****$2");
        //将手机号和token 存入本地缓存中
        window.sessionStorage.setItem("tel", tel);
        window.sessionStorage.setItem("token", res.result.token);
        //修改状态
        this.$store.commit("set_phone", tel);
        this.$store.commit("set_token", res.result.token);
        this.$router.push("/my");
      });
    },
  },
};
</script>
